<template>
  <div class="ps-5 pe-5">
    <div class="row">
      <div class="col-12">
        <div class="">

          <div class="h-auto">

            <div class="row h-100 align-items-center pt-3 m-0 pb-4">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
                <div class="card shadow sm">
                  <div class="card-body p-0" id="scrollTo">

                    <div class="row m-0 p-0" id="notificationList">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">

                        <!-- Loading -->
                        <div v-if="loading">
                          <div class="col-12 col-xs-12 col-sm-6 offset-sm-3 col-md-6 offset-md-3 col-lg-6 offset-lg-3 position-relative h-100">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                              <Loader :loading="loading" :small="false"></Loader>
                            </div>
                          </div>
                        </div>

                        <!-- Notifications -->
                        <div v-if="!loading">
                          <div class="col-12 col-xs-12 col-sm-6 offset-sm-3 col-md-6 offset-md-3 col-lg-6 offset-lg-3 position-relative h-100">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">

                              <ul class="list-unstyled user-notifications-dropdown">
                                <li v-for="(notification, index) in userNotifications" :key="index" class="notification-list-li">
                                  <router-link :to="{path: notification.data.url + '/' + notification.data.chat_room_id}" class="text-decoration-none" @click.native.prevent="markNotificationAsRead(notification.data.notification_id, notification.data.url, notification.data.chat_room_id)">
                                    <div :class="notification && notification.read_at && notification.read_at !== null ? 'notification-item text-center' : 'notification-item notification-unread text-center'">
                                      {{notification.data.message}}
                                      <hr class="dropdown-divider"/>
                                      {{ ( moment.utc(notification.created_at).isBefore(moment.utc().subtract(1, 'days')) ) ? moment.utc(notification.created_at).format('DD-MM-YYYY') : moment.utc(notification.created_at).format('H:mm')}}
                                    </div>
                                  </router-link>
                                </li>
                                <li v-if="userNotifications && userNotifications.length === 0" class="text-center"><p class="dropdown-item excerpt-in-login">No notifications</p></li>
                              </ul>

                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                        <!-- Pagination -->
                        <ul class="pagination justify-content-center" v-if="pagination && !loading">
                          <li class="page-item" :class="{disabled: metaData.current_page === 1}">
                            <a class="page-link" @click="previous"><span aria-hidden="true">&laquo;</span></a>
                          </li>
                          <!-- HERE I NEED TO PROVIDE MY OWN CALCULATED number of pages? instead of last_page metaData.last_page -->
                          <li
                              v-for="number in paginationData"
                              class="page-item"
                              :class="{active: metaData.current_page === number}"
                          >
                            <a class="page-link" @click="navigateTo(null, number)">{{ number }}</a>
                          </li>
                          <li class="page-item" :class="{disabled: metaData.current_page === metaData.last_page}">
                            <a class="page-link" @click="next"><span aria-hidden="true">&raquo;</span></a>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loader from "../Loader";
import ValidationErrors from "../ValidationErrors";
import {mapActions} from "vuex";

export default {
  name:"DashboardNotifications",
  components: {
    'ValidationErrors': ValidationErrors,
    'Loader': Loader
  },
  data(){
    return {
      user: (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) ? this.$store.state.auth.user : null,
      loading: true,
      processing: false,
      userNotifications: [],
      validationErrors: '',
      validationMessage: '',
      validationSuccess: '',
      showError: false,
      // Paginate
      noData: true,
      pagination: false,
      paginationData: '',
      linksData: '',
      metaData: ''
    }
  },
  async mounted() {
    this.processing = true;
    this.loading = true;
    if (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) {
      // Get user notifications
      await this.getUserNotifications();

      if (
          this.userNotifications &&
          this.userNotifications.length > 0
      ) {
        this.noData = false;
        this.pagination = true;
        this.calculatePagination();
      } else {
        this.noData = true;
        this.pagination = false;
      }
    }
    this.loading = false;
    this.processing = false;
  },
  computed:{

  },
  async created() {

  },
  beforeDestroy() {

  },
  methods: {
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    ...mapActions({
      setNotifications:'notifications/setNotifications',
    }),
    async getNotifications() {
      if (this.$store.state.auth && this.$store.state.auth.user.id) {
        this.processing = true;
        await axios.post('api/user/' + this.$store.state.auth.user.id + '/notifications').then(({data})=> {
          // this.notifications = data;
          this.setNotifications(data);
          this.validationErrors = null;
        }).catch(({response}) => { // {response: {data}}
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            // this.validationMessage = data.message;
          }
        }).finally(() => {
          this.processing = false
        })
      }
    },
    async markNotificationAsRead(notificationId, url, chatRoomId) {
      if (this.$store.state.auth && this.$store.state.auth.user.id && notificationId && url && chatRoomId) {
        this.processing = true;
        await axios.post('api/user/' + this.$store.state.auth.user.id + '/notifications/' + notificationId).then(({data})=> {
          this.getNotifications();
          this.validationErrors = null;
        }).catch(({response}) => { // {response: {data}}
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            // this.validationMessage = data.message;
          }
        }).finally(() => {
          this.processing = false
        })
      }
    },
    async getUserNotifications(type = null) {
      this.processing = true;
      this.loading = true;

      let formData = new FormData();

      formData.append('type', 'all');

      await axios.post('api/user/' + this.$store.state.auth.user.id + '/notifications', formData).then(async ({data}) => {
        this.userNotifications = data.data;
        this.metaData = data;
        this.linksData = data.links;

        this.showError = false;
        this.validationSuccess = null;
        this.validationMessage = '';
        this.validationErrors = '';
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.loading = false;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
        }
      }).finally(() => {
        this.loading = false;
        this.processing = false;
      })
    },
    /* START PAGINATION */
    navigateTo(url, page = null) {
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.loading = true;
      if (page) {
        url = this.metaData.first_page_url.substring(0, this.metaData.first_page_url.lastIndexOf('=') + 1) + page;
      }

      let formData = new FormData();
      formData.append('type', 'all');

      axios.post(url, formData).then(({data})=>{
        // I should really trigger setProperties from vuex
        this.userNotifications = data.data;
        this.metaData = data;
        this.calculatePagination();
      }).catch(({response})=>{
        alert(response.data.message)
      }).finally(()=> {
        this.processing = false
        this.loading = false;
      })
    },
    previous() {
      if (this.metaData.prev_page_url) {
        this.navigateTo(this.metaData.prev_page_url)
      }
    },
    next() {
      if (this.metaData.next_page_url) {
        this.navigateTo(this.metaData.next_page_url)
      }
    },
    calculatePagination() {
      if(this.metaData && this.linksData) {

        // Current page any but total pages less than 10
        if (this.metaData.last_page < 10) {
          this.paginationData = this.metaData.last_page;
        }

        // Current page 2 or less and last_page pages more than 10
        if (this.metaData.current_page <= 2 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 3 and last_page pages more than 10
        if (this.metaData.current_page === 3 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 4 and last_page pages more than 10
        if (this.metaData.current_page === 4 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, 5, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 5 and last_page pages more than 10
        if (this.metaData.current_page === 5 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 6 or more and last_page pages more than 10
        if (this.metaData.current_page >=6 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page almost or equal last_page which is more than 10
        if (this.metaData.last_page >= 10 &&
            (this.metaData.current_page === this.metaData.last_page ||
                this.metaData.current_page === this.metaData.last_page - 1 ||
                this.metaData.current_page === this.metaData.last_page - 2
            )
        ) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 3, this.metaData.last_page - 2, this.metaData.last_page - 1, this.metaData.last_page]
        }
      }
    }
    /* END PAGINATION */
  }
}
</script>


<style scoped lang="scss">

/* start tooltip - popups info on buttons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  margin-left: 10px;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on buttons */

.dashboard-header {
  letter-spacing: 2px;
}

.pagination {
  cursor: pointer;
}

#notificationList {
  height: auto;
  min-height: 400px;
}

.user-notifications-dropdown {
  width: 100%;
  //min-width: 250px !important;
  //max-height: 500px;
  //right: 0 !important;
  //left: unset !important;
  //overflow-x: hidden;
  //overflow-y: scroll;
  z-index: 1000;
  .notification-list-li {
    margin: 5px;
  }
  .notification-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 200;
    font-size: 12px;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #e9ecef;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .notification-item:hover {
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    background-color: #e9ecef;
  }
  .notification-unread {
    font-weight: 600 !important;
  }
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 767px) {

}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media (max-height: 800px) and (min-width: 1200px) {

}
@media screen and (min-width: 1680px) {

}
@media (min-height: 1200px) and (min-width: 1600px) {

}
@media screen and (min-width: 1920px) {

}

</style>